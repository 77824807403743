import React from "react";
import { objectsToArrayOfObjects } from "helpers/objectsToArrayofObjects";
import { getStaticImgProps } from "helpers/getImgProps";
import * as styles from "./techStack.module.scss";

const TechStack = (techstack) => {
  const stackList = objectsToArrayOfObjects(techstack).map(
    ({ name, logo }, i) => {
      return (
        <div
          key={i}
          className={`${styles.stackContainer} d-flex justify-content-start align-items-center mb-20`}
        >
          <p className={styles.logo}>
            <img {...getStaticImgProps(logo)} />
          </p>
          <p className={`${styles.name} ml-20`}>{name}</p>
        </div>
      );
    }
  );
  return (
    <div className={styles.wrapper}>
      <p className={`${styles.title} mt-100 mb-60`}>Technology Stack</p>
      <div
        className={`${styles.stackListContainer} pa-20 d-flex justify-content-start align-items-center flex-wrap`}
      >
        {stackList}
      </div>
    </div>
  );
};

export default TechStack;
