export const getSingleProjectDetailsProps = ({
  strapiProject: {
    projectTitle,
    projectSubtitle,
    slug,
    about,
    location,
    website,
    challenge,
    result,
    image,
    imageAbout,
    resultImages,
    tech_stacks,
  },
}) => {
  return {
    projectTitle,
    projectSubtitle,
    slug,
    about,
    location,
    website,
    challenge,
    result,
    image,
    imageAbout,
    resultImages,
    tech_stacks,
  };
};
