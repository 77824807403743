// extracted by mini-css-extract-plugin
export var banner = "projectDetails-module--banner--859ad";
export var imageList = "projectDetails-module--imageList--5c7b2";
export var images = "projectDetails-module--images--2b5b8";
export var img = "projectDetails-module--img--cf49e";
export var imgContainer = "projectDetails-module--imgContainer--b226b";
export var ma0 = "projectDetails-module--ma-0--f84df";
export var ma10 = "projectDetails-module--ma-10--3479f";
export var ma100 = "projectDetails-module--ma-100--8e389";
export var ma105 = "projectDetails-module--ma-105--afda7";
export var ma110 = "projectDetails-module--ma-110--a5b56";
export var ma115 = "projectDetails-module--ma-115--eb694";
export var ma12 = "projectDetails-module--ma-12--bd32e";
export var ma120 = "projectDetails-module--ma-120--c33cc";
export var ma130 = "projectDetails-module--ma-130--e3f8a";
export var ma140 = "projectDetails-module--ma-140--03597";
export var ma15 = "projectDetails-module--ma-15--df32d";
export var ma150 = "projectDetails-module--ma-150--b63de";
export var ma16 = "projectDetails-module--ma-16--acc87";
export var ma18 = "projectDetails-module--ma-18--67e26";
export var ma185 = "projectDetails-module--ma-185--beaa7";
export var ma20 = "projectDetails-module--ma-20--78ef9";
export var ma25 = "projectDetails-module--ma-25--1b421";
export var ma30 = "projectDetails-module--ma-30--019ff";
export var ma35 = "projectDetails-module--ma-35--d9761";
export var ma40 = "projectDetails-module--ma-40--0aa01";
export var ma45 = "projectDetails-module--ma-45--e102d";
export var ma48 = "projectDetails-module--ma-48--2aaf9";
export var ma5 = "projectDetails-module--ma-5--f029a";
export var ma50 = "projectDetails-module--ma-50--5af7c";
export var ma55 = "projectDetails-module--ma-55--6fce7";
export var ma60 = "projectDetails-module--ma-60--96610";
export var ma70 = "projectDetails-module--ma-70--77e56";
export var ma80 = "projectDetails-module--ma-80--2c716";
export var ma85 = "projectDetails-module--ma-85--7e9bd";
export var ma90 = "projectDetails-module--ma-90--ffae5";
export var ma95 = "projectDetails-module--ma-95--46f2a";
export var mb0 = "projectDetails-module--mb-0--22d97";
export var mb10 = "projectDetails-module--mb-10--91554";
export var mb100 = "projectDetails-module--mb-100--01e1f";
export var mb105 = "projectDetails-module--mb-105--ba2ab";
export var mb110 = "projectDetails-module--mb-110--71d68";
export var mb115 = "projectDetails-module--mb-115--4701e";
export var mb12 = "projectDetails-module--mb-12--fce9c";
export var mb120 = "projectDetails-module--mb-120--725a9";
export var mb130 = "projectDetails-module--mb-130--fd922";
export var mb140 = "projectDetails-module--mb-140--eeaea";
export var mb15 = "projectDetails-module--mb-15--fe11d";
export var mb150 = "projectDetails-module--mb-150--517e1";
export var mb16 = "projectDetails-module--mb-16--e382e";
export var mb18 = "projectDetails-module--mb-18--55c2e";
export var mb185 = "projectDetails-module--mb-185--e126e";
export var mb20 = "projectDetails-module--mb-20--f6382";
export var mb25 = "projectDetails-module--mb-25--185ac";
export var mb30 = "projectDetails-module--mb-30--b1b6d";
export var mb35 = "projectDetails-module--mb-35--da043";
export var mb40 = "projectDetails-module--mb-40--eda85";
export var mb45 = "projectDetails-module--mb-45--c3057";
export var mb48 = "projectDetails-module--mb-48--ad115";
export var mb5 = "projectDetails-module--mb-5--19db2";
export var mb50 = "projectDetails-module--mb-50--4077a";
export var mb55 = "projectDetails-module--mb-55--3c0a7";
export var mb60 = "projectDetails-module--mb-60--77f9a";
export var mb70 = "projectDetails-module--mb-70--eb24d";
export var mb80 = "projectDetails-module--mb-80--e855c";
export var mb85 = "projectDetails-module--mb-85--d8dca";
export var mb90 = "projectDetails-module--mb-90--d7177";
export var mb95 = "projectDetails-module--mb-95--3a1fa";
export var mh0 = "projectDetails-module--mh-0--ec703";
export var mh10 = "projectDetails-module--mh-10--3e797";
export var mh100 = "projectDetails-module--mh-100--cc9d1";
export var mh105 = "projectDetails-module--mh-105--27ff1";
export var mh110 = "projectDetails-module--mh-110--c8f0b";
export var mh115 = "projectDetails-module--mh-115--094ed";
export var mh12 = "projectDetails-module--mh-12--5dc21";
export var mh120 = "projectDetails-module--mh-120--29570";
export var mh130 = "projectDetails-module--mh-130--36b4c";
export var mh140 = "projectDetails-module--mh-140--6506a";
export var mh15 = "projectDetails-module--mh-15--478a0";
export var mh150 = "projectDetails-module--mh-150--8388f";
export var mh16 = "projectDetails-module--mh-16--d03ad";
export var mh18 = "projectDetails-module--mh-18--89da3";
export var mh185 = "projectDetails-module--mh-185--23bc9";
export var mh20 = "projectDetails-module--mh-20--e2847";
export var mh25 = "projectDetails-module--mh-25--db003";
export var mh30 = "projectDetails-module--mh-30--c1183";
export var mh35 = "projectDetails-module--mh-35--deb39";
export var mh40 = "projectDetails-module--mh-40--89f6e";
export var mh45 = "projectDetails-module--mh-45--8df6b";
export var mh48 = "projectDetails-module--mh-48--2617d";
export var mh5 = "projectDetails-module--mh-5--8b9a8";
export var mh50 = "projectDetails-module--mh-50--a3e01";
export var mh55 = "projectDetails-module--mh-55--ae4b0";
export var mh60 = "projectDetails-module--mh-60--2c19a";
export var mh70 = "projectDetails-module--mh-70--bc031";
export var mh80 = "projectDetails-module--mh-80--44e3f";
export var mh85 = "projectDetails-module--mh-85--86908";
export var mh90 = "projectDetails-module--mh-90--46549";
export var mh95 = "projectDetails-module--mh-95--c699e";
export var ml0 = "projectDetails-module--ml-0--f1e88";
export var ml10 = "projectDetails-module--ml-10--3df8b";
export var ml100 = "projectDetails-module--ml-100--ec645";
export var ml105 = "projectDetails-module--ml-105--503c9";
export var ml110 = "projectDetails-module--ml-110--75ab2";
export var ml115 = "projectDetails-module--ml-115--d4fbf";
export var ml12 = "projectDetails-module--ml-12--da852";
export var ml120 = "projectDetails-module--ml-120--943cd";
export var ml130 = "projectDetails-module--ml-130--dfdd4";
export var ml140 = "projectDetails-module--ml-140--3e5e9";
export var ml15 = "projectDetails-module--ml-15--fc5d1";
export var ml150 = "projectDetails-module--ml-150--7aff2";
export var ml16 = "projectDetails-module--ml-16--27991";
export var ml18 = "projectDetails-module--ml-18--7731a";
export var ml185 = "projectDetails-module--ml-185--e05cb";
export var ml20 = "projectDetails-module--ml-20--03581";
export var ml25 = "projectDetails-module--ml-25--77ded";
export var ml30 = "projectDetails-module--ml-30--b4ad7";
export var ml35 = "projectDetails-module--ml-35--a51e8";
export var ml40 = "projectDetails-module--ml-40--66bcd";
export var ml45 = "projectDetails-module--ml-45--c08a4";
export var ml48 = "projectDetails-module--ml-48--c63e8";
export var ml5 = "projectDetails-module--ml-5--c3f6c";
export var ml50 = "projectDetails-module--ml-50--37669";
export var ml55 = "projectDetails-module--ml-55--31205";
export var ml60 = "projectDetails-module--ml-60--d911b";
export var ml70 = "projectDetails-module--ml-70--2fd44";
export var ml80 = "projectDetails-module--ml-80--b7fb6";
export var ml85 = "projectDetails-module--ml-85--49bd4";
export var ml90 = "projectDetails-module--ml-90--d01f7";
export var ml95 = "projectDetails-module--ml-95--055e6";
export var mr0 = "projectDetails-module--mr-0--8b1fd";
export var mr10 = "projectDetails-module--mr-10--7c1a8";
export var mr100 = "projectDetails-module--mr-100--cc99a";
export var mr105 = "projectDetails-module--mr-105--b09e6";
export var mr110 = "projectDetails-module--mr-110--39ee6";
export var mr115 = "projectDetails-module--mr-115--7d8e5";
export var mr12 = "projectDetails-module--mr-12--271e4";
export var mr120 = "projectDetails-module--mr-120--7fd28";
export var mr130 = "projectDetails-module--mr-130--42795";
export var mr140 = "projectDetails-module--mr-140--75a85";
export var mr15 = "projectDetails-module--mr-15--0aa36";
export var mr150 = "projectDetails-module--mr-150--ee884";
export var mr16 = "projectDetails-module--mr-16--48570";
export var mr18 = "projectDetails-module--mr-18--7ce44";
export var mr185 = "projectDetails-module--mr-185--be865";
export var mr20 = "projectDetails-module--mr-20--76fa3";
export var mr25 = "projectDetails-module--mr-25--fefef";
export var mr30 = "projectDetails-module--mr-30--8ec4b";
export var mr35 = "projectDetails-module--mr-35--2e97e";
export var mr40 = "projectDetails-module--mr-40--2aa25";
export var mr45 = "projectDetails-module--mr-45--1b14d";
export var mr48 = "projectDetails-module--mr-48--d87d4";
export var mr5 = "projectDetails-module--mr-5--0507f";
export var mr50 = "projectDetails-module--mr-50--d98b5";
export var mr55 = "projectDetails-module--mr-55--ffed5";
export var mr60 = "projectDetails-module--mr-60--03c92";
export var mr70 = "projectDetails-module--mr-70--b539b";
export var mr80 = "projectDetails-module--mr-80--9eee1";
export var mr85 = "projectDetails-module--mr-85--26a7b";
export var mr90 = "projectDetails-module--mr-90--cda49";
export var mr95 = "projectDetails-module--mr-95--4b719";
export var mt0 = "projectDetails-module--mt-0--640a3";
export var mt10 = "projectDetails-module--mt-10--06a8d";
export var mt100 = "projectDetails-module--mt-100--0ea41";
export var mt105 = "projectDetails-module--mt-105--86cb0";
export var mt110 = "projectDetails-module--mt-110--7c973";
export var mt115 = "projectDetails-module--mt-115--e0709";
export var mt12 = "projectDetails-module--mt-12--b4f92";
export var mt120 = "projectDetails-module--mt-120--e5b76";
export var mt130 = "projectDetails-module--mt-130--53859";
export var mt140 = "projectDetails-module--mt-140--52d37";
export var mt15 = "projectDetails-module--mt-15--cb096";
export var mt150 = "projectDetails-module--mt-150--79b93";
export var mt16 = "projectDetails-module--mt-16--51224";
export var mt18 = "projectDetails-module--mt-18--2701d";
export var mt185 = "projectDetails-module--mt-185--70302";
export var mt20 = "projectDetails-module--mt-20--1c8bd";
export var mt25 = "projectDetails-module--mt-25--b1269";
export var mt30 = "projectDetails-module--mt-30--a48f8";
export var mt35 = "projectDetails-module--mt-35--0a3ad";
export var mt40 = "projectDetails-module--mt-40--380ec";
export var mt45 = "projectDetails-module--mt-45--a6948";
export var mt48 = "projectDetails-module--mt-48--ad43d";
export var mt5 = "projectDetails-module--mt-5--e2db4";
export var mt50 = "projectDetails-module--mt-50--280dd";
export var mt55 = "projectDetails-module--mt-55--130f0";
export var mt60 = "projectDetails-module--mt-60--e2257";
export var mt70 = "projectDetails-module--mt-70--69fc0";
export var mt80 = "projectDetails-module--mt-80--fa155";
export var mt85 = "projectDetails-module--mt-85--6933c";
export var mt90 = "projectDetails-module--mt-90--0b52e";
export var mt95 = "projectDetails-module--mt-95--57536";
export var mv0 = "projectDetails-module--mv-0--1cd2a";
export var mv10 = "projectDetails-module--mv-10--2ffba";
export var mv100 = "projectDetails-module--mv-100--64e9a";
export var mv105 = "projectDetails-module--mv-105--bd575";
export var mv110 = "projectDetails-module--mv-110--0b43e";
export var mv115 = "projectDetails-module--mv-115--d61a7";
export var mv12 = "projectDetails-module--mv-12--aad77";
export var mv120 = "projectDetails-module--mv-120--047a0";
export var mv130 = "projectDetails-module--mv-130--45ced";
export var mv140 = "projectDetails-module--mv-140--134f0";
export var mv15 = "projectDetails-module--mv-15--73e8f";
export var mv150 = "projectDetails-module--mv-150--4dfd5";
export var mv16 = "projectDetails-module--mv-16--872ed";
export var mv18 = "projectDetails-module--mv-18--5c448";
export var mv185 = "projectDetails-module--mv-185--eded0";
export var mv20 = "projectDetails-module--mv-20--82613";
export var mv25 = "projectDetails-module--mv-25--42e96";
export var mv30 = "projectDetails-module--mv-30--38b06";
export var mv35 = "projectDetails-module--mv-35--d7aaf";
export var mv40 = "projectDetails-module--mv-40--ada5b";
export var mv45 = "projectDetails-module--mv-45--f280b";
export var mv48 = "projectDetails-module--mv-48--ea198";
export var mv5 = "projectDetails-module--mv-5--3c626";
export var mv50 = "projectDetails-module--mv-50--34824";
export var mv55 = "projectDetails-module--mv-55--fdd03";
export var mv60 = "projectDetails-module--mv-60--4d8e4";
export var mv70 = "projectDetails-module--mv-70--49f9e";
export var mv80 = "projectDetails-module--mv-80--f49cd";
export var mv85 = "projectDetails-module--mv-85--c62b5";
export var mv90 = "projectDetails-module--mv-90--3f82f";
export var mv95 = "projectDetails-module--mv-95--a207d";
export var pa0 = "projectDetails-module--pa-0--4cd9f";
export var pa10 = "projectDetails-module--pa-10--6d05c";
export var pa100 = "projectDetails-module--pa-100--b6e7c";
export var pa105 = "projectDetails-module--pa-105--657a3";
export var pa110 = "projectDetails-module--pa-110--de80a";
export var pa115 = "projectDetails-module--pa-115--b135c";
export var pa12 = "projectDetails-module--pa-12--22944";
export var pa120 = "projectDetails-module--pa-120--38bb7";
export var pa130 = "projectDetails-module--pa-130--064c9";
export var pa140 = "projectDetails-module--pa-140--712a2";
export var pa15 = "projectDetails-module--pa-15--46ff3";
export var pa150 = "projectDetails-module--pa-150--bff45";
export var pa16 = "projectDetails-module--pa-16--ea069";
export var pa18 = "projectDetails-module--pa-18--974f9";
export var pa185 = "projectDetails-module--pa-185--d6434";
export var pa20 = "projectDetails-module--pa-20--0e1e0";
export var pa25 = "projectDetails-module--pa-25--bb6a0";
export var pa30 = "projectDetails-module--pa-30--312bb";
export var pa35 = "projectDetails-module--pa-35--67003";
export var pa40 = "projectDetails-module--pa-40--0b4f6";
export var pa45 = "projectDetails-module--pa-45--8c60f";
export var pa48 = "projectDetails-module--pa-48--5cb77";
export var pa5 = "projectDetails-module--pa-5--a2289";
export var pa50 = "projectDetails-module--pa-50--8ac43";
export var pa55 = "projectDetails-module--pa-55--ae207";
export var pa60 = "projectDetails-module--pa-60--850aa";
export var pa70 = "projectDetails-module--pa-70--3fe75";
export var pa80 = "projectDetails-module--pa-80--d1935";
export var pa85 = "projectDetails-module--pa-85--b976f";
export var pa90 = "projectDetails-module--pa-90--0fe1f";
export var pa95 = "projectDetails-module--pa-95--1b752";
export var pb0 = "projectDetails-module--pb-0--4ba2c";
export var pb10 = "projectDetails-module--pb-10--c65b2";
export var pb100 = "projectDetails-module--pb-100--7f2fe";
export var pb105 = "projectDetails-module--pb-105--a77e4";
export var pb110 = "projectDetails-module--pb-110--55a46";
export var pb115 = "projectDetails-module--pb-115--a03b0";
export var pb12 = "projectDetails-module--pb-12--e1cec";
export var pb120 = "projectDetails-module--pb-120--b2271";
export var pb130 = "projectDetails-module--pb-130--4551a";
export var pb140 = "projectDetails-module--pb-140--358f2";
export var pb15 = "projectDetails-module--pb-15--5868b";
export var pb150 = "projectDetails-module--pb-150--eb379";
export var pb16 = "projectDetails-module--pb-16--7a59c";
export var pb18 = "projectDetails-module--pb-18--8b900";
export var pb185 = "projectDetails-module--pb-185--35524";
export var pb20 = "projectDetails-module--pb-20--ecbe7";
export var pb25 = "projectDetails-module--pb-25--4b94d";
export var pb30 = "projectDetails-module--pb-30--b89fd";
export var pb35 = "projectDetails-module--pb-35--ca3c0";
export var pb40 = "projectDetails-module--pb-40--e6805";
export var pb45 = "projectDetails-module--pb-45--85462";
export var pb48 = "projectDetails-module--pb-48--0dbd0";
export var pb5 = "projectDetails-module--pb-5--cabec";
export var pb50 = "projectDetails-module--pb-50--9ba95";
export var pb55 = "projectDetails-module--pb-55--60140";
export var pb60 = "projectDetails-module--pb-60--982c2";
export var pb70 = "projectDetails-module--pb-70--c65ac";
export var pb80 = "projectDetails-module--pb-80--5123a";
export var pb85 = "projectDetails-module--pb-85--2692b";
export var pb90 = "projectDetails-module--pb-90--64978";
export var pb95 = "projectDetails-module--pb-95--24e7e";
export var ph0 = "projectDetails-module--ph-0--e8d4e";
export var ph10 = "projectDetails-module--ph-10--24cad";
export var ph100 = "projectDetails-module--ph-100--49535";
export var ph105 = "projectDetails-module--ph-105--ff285";
export var ph110 = "projectDetails-module--ph-110--f72ad";
export var ph115 = "projectDetails-module--ph-115--191b5";
export var ph12 = "projectDetails-module--ph-12--7764d";
export var ph120 = "projectDetails-module--ph-120--0dbc0";
export var ph130 = "projectDetails-module--ph-130--cbae0";
export var ph140 = "projectDetails-module--ph-140--fd044";
export var ph15 = "projectDetails-module--ph-15--2709b";
export var ph150 = "projectDetails-module--ph-150--95a39";
export var ph16 = "projectDetails-module--ph-16--29989";
export var ph18 = "projectDetails-module--ph-18--daa25";
export var ph185 = "projectDetails-module--ph-185--09ec3";
export var ph20 = "projectDetails-module--ph-20--14d43";
export var ph25 = "projectDetails-module--ph-25--5db04";
export var ph30 = "projectDetails-module--ph-30--68bd9";
export var ph35 = "projectDetails-module--ph-35--b0ea1";
export var ph40 = "projectDetails-module--ph-40--334e5";
export var ph45 = "projectDetails-module--ph-45--d0134";
export var ph48 = "projectDetails-module--ph-48--b0f7f";
export var ph5 = "projectDetails-module--ph-5--29de5";
export var ph50 = "projectDetails-module--ph-50--26e06";
export var ph55 = "projectDetails-module--ph-55--83af1";
export var ph60 = "projectDetails-module--ph-60--d1595";
export var ph70 = "projectDetails-module--ph-70--69d10";
export var ph80 = "projectDetails-module--ph-80--e2394";
export var ph85 = "projectDetails-module--ph-85--ddaa3";
export var ph90 = "projectDetails-module--ph-90--af818";
export var ph95 = "projectDetails-module--ph-95--892f5";
export var pl0 = "projectDetails-module--pl-0--2f622";
export var pl10 = "projectDetails-module--pl-10--53406";
export var pl100 = "projectDetails-module--pl-100--1662c";
export var pl105 = "projectDetails-module--pl-105--385e7";
export var pl110 = "projectDetails-module--pl-110--ced99";
export var pl115 = "projectDetails-module--pl-115--342d9";
export var pl12 = "projectDetails-module--pl-12--cba92";
export var pl120 = "projectDetails-module--pl-120--81719";
export var pl130 = "projectDetails-module--pl-130--acb61";
export var pl140 = "projectDetails-module--pl-140--648d7";
export var pl15 = "projectDetails-module--pl-15--ca793";
export var pl150 = "projectDetails-module--pl-150--2bbac";
export var pl16 = "projectDetails-module--pl-16--4dc2b";
export var pl18 = "projectDetails-module--pl-18--38e25";
export var pl185 = "projectDetails-module--pl-185--6a1fb";
export var pl20 = "projectDetails-module--pl-20--d3343";
export var pl25 = "projectDetails-module--pl-25--1563c";
export var pl30 = "projectDetails-module--pl-30--8f0ee";
export var pl35 = "projectDetails-module--pl-35--434bd";
export var pl40 = "projectDetails-module--pl-40--2b985";
export var pl45 = "projectDetails-module--pl-45--903f1";
export var pl48 = "projectDetails-module--pl-48--995e3";
export var pl5 = "projectDetails-module--pl-5--4b0ef";
export var pl50 = "projectDetails-module--pl-50--31430";
export var pl55 = "projectDetails-module--pl-55--d4ca9";
export var pl60 = "projectDetails-module--pl-60--94d39";
export var pl70 = "projectDetails-module--pl-70--6ab3b";
export var pl80 = "projectDetails-module--pl-80--0298d";
export var pl85 = "projectDetails-module--pl-85--fa909";
export var pl90 = "projectDetails-module--pl-90--bb69e";
export var pl95 = "projectDetails-module--pl-95--cbded";
export var pr0 = "projectDetails-module--pr-0--52121";
export var pr10 = "projectDetails-module--pr-10--f4d0d";
export var pr100 = "projectDetails-module--pr-100--caf93";
export var pr105 = "projectDetails-module--pr-105--757d7";
export var pr110 = "projectDetails-module--pr-110--6b134";
export var pr115 = "projectDetails-module--pr-115--52b06";
export var pr12 = "projectDetails-module--pr-12--27a4e";
export var pr120 = "projectDetails-module--pr-120--5530d";
export var pr130 = "projectDetails-module--pr-130--fa8fe";
export var pr140 = "projectDetails-module--pr-140--d5c39";
export var pr15 = "projectDetails-module--pr-15--27240";
export var pr150 = "projectDetails-module--pr-150--876b8";
export var pr16 = "projectDetails-module--pr-16--14899";
export var pr18 = "projectDetails-module--pr-18--4052e";
export var pr185 = "projectDetails-module--pr-185--fffba";
export var pr20 = "projectDetails-module--pr-20--398fd";
export var pr25 = "projectDetails-module--pr-25--de745";
export var pr30 = "projectDetails-module--pr-30--7415b";
export var pr35 = "projectDetails-module--pr-35--cf0fc";
export var pr40 = "projectDetails-module--pr-40--988cb";
export var pr45 = "projectDetails-module--pr-45--6bba8";
export var pr48 = "projectDetails-module--pr-48--5b301";
export var pr5 = "projectDetails-module--pr-5--e32f5";
export var pr50 = "projectDetails-module--pr-50--e942f";
export var pr55 = "projectDetails-module--pr-55--2822f";
export var pr60 = "projectDetails-module--pr-60--3025c";
export var pr70 = "projectDetails-module--pr-70--3e41c";
export var pr80 = "projectDetails-module--pr-80--6cc97";
export var pr85 = "projectDetails-module--pr-85--15982";
export var pr90 = "projectDetails-module--pr-90--4784b";
export var pr95 = "projectDetails-module--pr-95--0906c";
export var projectSubtitle = "projectDetails-module--projectSubtitle--c75cd";
export var projectTitle = "projectDetails-module--projectTitle--d391f";
export var pt0 = "projectDetails-module--pt-0--1dcc6";
export var pt10 = "projectDetails-module--pt-10--2f8dd";
export var pt100 = "projectDetails-module--pt-100--1ff6a";
export var pt105 = "projectDetails-module--pt-105--b2075";
export var pt110 = "projectDetails-module--pt-110--8cea5";
export var pt115 = "projectDetails-module--pt-115--458e5";
export var pt12 = "projectDetails-module--pt-12--f2ab4";
export var pt120 = "projectDetails-module--pt-120--66c35";
export var pt130 = "projectDetails-module--pt-130--a041e";
export var pt140 = "projectDetails-module--pt-140--c0602";
export var pt15 = "projectDetails-module--pt-15--b00c0";
export var pt150 = "projectDetails-module--pt-150--683bf";
export var pt16 = "projectDetails-module--pt-16--ebd95";
export var pt18 = "projectDetails-module--pt-18--f3ff2";
export var pt185 = "projectDetails-module--pt-185--46dae";
export var pt20 = "projectDetails-module--pt-20--2d530";
export var pt25 = "projectDetails-module--pt-25--f9020";
export var pt30 = "projectDetails-module--pt-30--a96d5";
export var pt35 = "projectDetails-module--pt-35--1c2fa";
export var pt40 = "projectDetails-module--pt-40--b9732";
export var pt45 = "projectDetails-module--pt-45--88b7f";
export var pt48 = "projectDetails-module--pt-48--b0323";
export var pt5 = "projectDetails-module--pt-5--319a5";
export var pt50 = "projectDetails-module--pt-50--f1b96";
export var pt55 = "projectDetails-module--pt-55--90ce5";
export var pt60 = "projectDetails-module--pt-60--c3c2b";
export var pt70 = "projectDetails-module--pt-70--9fd8d";
export var pt80 = "projectDetails-module--pt-80--33bb6";
export var pt85 = "projectDetails-module--pt-85--aa3fa";
export var pt90 = "projectDetails-module--pt-90--72f79";
export var pt95 = "projectDetails-module--pt-95--9f7ca";
export var pv0 = "projectDetails-module--pv-0--84cb3";
export var pv10 = "projectDetails-module--pv-10--c260d";
export var pv100 = "projectDetails-module--pv-100--f299e";
export var pv105 = "projectDetails-module--pv-105--31469";
export var pv110 = "projectDetails-module--pv-110--8fa52";
export var pv115 = "projectDetails-module--pv-115--7d1e8";
export var pv12 = "projectDetails-module--pv-12--f86f6";
export var pv120 = "projectDetails-module--pv-120--5febb";
export var pv130 = "projectDetails-module--pv-130--5b690";
export var pv140 = "projectDetails-module--pv-140--9428f";
export var pv15 = "projectDetails-module--pv-15--f54de";
export var pv150 = "projectDetails-module--pv-150--784f9";
export var pv16 = "projectDetails-module--pv-16--90711";
export var pv18 = "projectDetails-module--pv-18--77b2a";
export var pv185 = "projectDetails-module--pv-185--31069";
export var pv20 = "projectDetails-module--pv-20--e5d4d";
export var pv25 = "projectDetails-module--pv-25--554da";
export var pv30 = "projectDetails-module--pv-30--43320";
export var pv35 = "projectDetails-module--pv-35--93337";
export var pv40 = "projectDetails-module--pv-40--0d9ed";
export var pv45 = "projectDetails-module--pv-45--580c4";
export var pv48 = "projectDetails-module--pv-48--ebc6f";
export var pv5 = "projectDetails-module--pv-5--2f839";
export var pv50 = "projectDetails-module--pv-50--c4f5a";
export var pv55 = "projectDetails-module--pv-55--900eb";
export var pv60 = "projectDetails-module--pv-60--cb227";
export var pv70 = "projectDetails-module--pv-70--62df0";
export var pv80 = "projectDetails-module--pv-80--d2c5b";
export var pv85 = "projectDetails-module--pv-85--0760e";
export var pv90 = "projectDetails-module--pv-90--a51cd";
export var pv95 = "projectDetails-module--pv-95--5ed86";
export var result = "projectDetails-module--result--385b7";
export var textContainer = "projectDetails-module--textContainer--a053b";
export var title = "projectDetails-module--title--83e3c";
export var wrapper = "projectDetails-module--wrapper--4c481";