// extracted by mini-css-extract-plugin
export var logo = "techStack-module--logo--c8511";
export var ma0 = "techStack-module--ma-0--0dd1d";
export var ma10 = "techStack-module--ma-10--6f0af";
export var ma100 = "techStack-module--ma-100--f6832";
export var ma105 = "techStack-module--ma-105--96b14";
export var ma110 = "techStack-module--ma-110--f62e7";
export var ma115 = "techStack-module--ma-115--6a530";
export var ma12 = "techStack-module--ma-12--033fd";
export var ma120 = "techStack-module--ma-120--784d7";
export var ma130 = "techStack-module--ma-130--b57ed";
export var ma140 = "techStack-module--ma-140--b3509";
export var ma15 = "techStack-module--ma-15--6ea92";
export var ma150 = "techStack-module--ma-150--7e1cb";
export var ma16 = "techStack-module--ma-16--6706c";
export var ma18 = "techStack-module--ma-18--457ec";
export var ma185 = "techStack-module--ma-185--43cbf";
export var ma20 = "techStack-module--ma-20--308ab";
export var ma25 = "techStack-module--ma-25--c25ac";
export var ma30 = "techStack-module--ma-30--e71aa";
export var ma35 = "techStack-module--ma-35--ae6c0";
export var ma40 = "techStack-module--ma-40--583aa";
export var ma45 = "techStack-module--ma-45--df9f8";
export var ma48 = "techStack-module--ma-48--57d62";
export var ma5 = "techStack-module--ma-5--fd662";
export var ma50 = "techStack-module--ma-50--099f9";
export var ma55 = "techStack-module--ma-55--282ae";
export var ma60 = "techStack-module--ma-60--b01ce";
export var ma70 = "techStack-module--ma-70--d7708";
export var ma80 = "techStack-module--ma-80--4b2f9";
export var ma85 = "techStack-module--ma-85--2c9ae";
export var ma90 = "techStack-module--ma-90--3ff78";
export var ma95 = "techStack-module--ma-95--c4e0d";
export var mb0 = "techStack-module--mb-0--10bee";
export var mb10 = "techStack-module--mb-10--6c208";
export var mb100 = "techStack-module--mb-100--31d2e";
export var mb105 = "techStack-module--mb-105--94080";
export var mb110 = "techStack-module--mb-110--f17ff";
export var mb115 = "techStack-module--mb-115--fef04";
export var mb12 = "techStack-module--mb-12--d2aea";
export var mb120 = "techStack-module--mb-120--f1cf6";
export var mb130 = "techStack-module--mb-130--798a9";
export var mb140 = "techStack-module--mb-140--8a473";
export var mb15 = "techStack-module--mb-15--5adad";
export var mb150 = "techStack-module--mb-150--db126";
export var mb16 = "techStack-module--mb-16--dff5b";
export var mb18 = "techStack-module--mb-18--ee281";
export var mb185 = "techStack-module--mb-185--d049f";
export var mb20 = "techStack-module--mb-20--04a85";
export var mb25 = "techStack-module--mb-25--09838";
export var mb30 = "techStack-module--mb-30--03aae";
export var mb35 = "techStack-module--mb-35--a5c8d";
export var mb40 = "techStack-module--mb-40--76f61";
export var mb45 = "techStack-module--mb-45--5c90a";
export var mb48 = "techStack-module--mb-48--13d94";
export var mb5 = "techStack-module--mb-5--7c412";
export var mb50 = "techStack-module--mb-50--45756";
export var mb55 = "techStack-module--mb-55--b47f9";
export var mb60 = "techStack-module--mb-60--f483d";
export var mb70 = "techStack-module--mb-70--de22f";
export var mb80 = "techStack-module--mb-80--ce479";
export var mb85 = "techStack-module--mb-85--3724d";
export var mb90 = "techStack-module--mb-90--910c9";
export var mb95 = "techStack-module--mb-95--d262f";
export var mh0 = "techStack-module--mh-0--8c494";
export var mh10 = "techStack-module--mh-10--c2bf7";
export var mh100 = "techStack-module--mh-100--7b43e";
export var mh105 = "techStack-module--mh-105--5696b";
export var mh110 = "techStack-module--mh-110--e90a5";
export var mh115 = "techStack-module--mh-115--41820";
export var mh12 = "techStack-module--mh-12--56f0c";
export var mh120 = "techStack-module--mh-120--6873b";
export var mh130 = "techStack-module--mh-130--ff21f";
export var mh140 = "techStack-module--mh-140--b1f48";
export var mh15 = "techStack-module--mh-15--d46ba";
export var mh150 = "techStack-module--mh-150--6c6a0";
export var mh16 = "techStack-module--mh-16--3bb85";
export var mh18 = "techStack-module--mh-18--809c3";
export var mh185 = "techStack-module--mh-185--53d71";
export var mh20 = "techStack-module--mh-20--17834";
export var mh25 = "techStack-module--mh-25--305a0";
export var mh30 = "techStack-module--mh-30--143f6";
export var mh35 = "techStack-module--mh-35--2287c";
export var mh40 = "techStack-module--mh-40--6fde0";
export var mh45 = "techStack-module--mh-45--762b1";
export var mh48 = "techStack-module--mh-48--79a70";
export var mh5 = "techStack-module--mh-5--88a2b";
export var mh50 = "techStack-module--mh-50--1ab74";
export var mh55 = "techStack-module--mh-55--63dcb";
export var mh60 = "techStack-module--mh-60--4d870";
export var mh70 = "techStack-module--mh-70--408d2";
export var mh80 = "techStack-module--mh-80--cfade";
export var mh85 = "techStack-module--mh-85--66e6f";
export var mh90 = "techStack-module--mh-90--b6500";
export var mh95 = "techStack-module--mh-95--2887c";
export var ml0 = "techStack-module--ml-0--e6fb4";
export var ml10 = "techStack-module--ml-10--c6a50";
export var ml100 = "techStack-module--ml-100--a1c02";
export var ml105 = "techStack-module--ml-105--1f973";
export var ml110 = "techStack-module--ml-110--c70dc";
export var ml115 = "techStack-module--ml-115--334ff";
export var ml12 = "techStack-module--ml-12--f9ba6";
export var ml120 = "techStack-module--ml-120--f4129";
export var ml130 = "techStack-module--ml-130--824bf";
export var ml140 = "techStack-module--ml-140--995a0";
export var ml15 = "techStack-module--ml-15--86565";
export var ml150 = "techStack-module--ml-150--35e96";
export var ml16 = "techStack-module--ml-16--d4362";
export var ml18 = "techStack-module--ml-18--6ed3f";
export var ml185 = "techStack-module--ml-185--1abc2";
export var ml20 = "techStack-module--ml-20--f4017";
export var ml25 = "techStack-module--ml-25--45172";
export var ml30 = "techStack-module--ml-30--f8bd0";
export var ml35 = "techStack-module--ml-35--2147b";
export var ml40 = "techStack-module--ml-40--890a0";
export var ml45 = "techStack-module--ml-45--09b3f";
export var ml48 = "techStack-module--ml-48--a3564";
export var ml5 = "techStack-module--ml-5--72e0e";
export var ml50 = "techStack-module--ml-50--be536";
export var ml55 = "techStack-module--ml-55--cadfb";
export var ml60 = "techStack-module--ml-60--00c9d";
export var ml70 = "techStack-module--ml-70--58c21";
export var ml80 = "techStack-module--ml-80--f60bd";
export var ml85 = "techStack-module--ml-85--f894e";
export var ml90 = "techStack-module--ml-90--c08f9";
export var ml95 = "techStack-module--ml-95--1af9d";
export var mr0 = "techStack-module--mr-0--78a15";
export var mr10 = "techStack-module--mr-10--9e2bf";
export var mr100 = "techStack-module--mr-100--4d518";
export var mr105 = "techStack-module--mr-105--66d10";
export var mr110 = "techStack-module--mr-110--098be";
export var mr115 = "techStack-module--mr-115--8f96a";
export var mr12 = "techStack-module--mr-12--f4719";
export var mr120 = "techStack-module--mr-120--5a4c2";
export var mr130 = "techStack-module--mr-130--1287b";
export var mr140 = "techStack-module--mr-140--b5804";
export var mr15 = "techStack-module--mr-15--cd4c4";
export var mr150 = "techStack-module--mr-150--600e9";
export var mr16 = "techStack-module--mr-16--af153";
export var mr18 = "techStack-module--mr-18--36003";
export var mr185 = "techStack-module--mr-185--d6dd7";
export var mr20 = "techStack-module--mr-20--368f9";
export var mr25 = "techStack-module--mr-25--36471";
export var mr30 = "techStack-module--mr-30--a144d";
export var mr35 = "techStack-module--mr-35--7cdba";
export var mr40 = "techStack-module--mr-40--d4051";
export var mr45 = "techStack-module--mr-45--0a747";
export var mr48 = "techStack-module--mr-48--2fe54";
export var mr5 = "techStack-module--mr-5--50e58";
export var mr50 = "techStack-module--mr-50--bc94a";
export var mr55 = "techStack-module--mr-55--f7045";
export var mr60 = "techStack-module--mr-60--fd3fe";
export var mr70 = "techStack-module--mr-70--6af5d";
export var mr80 = "techStack-module--mr-80--55a52";
export var mr85 = "techStack-module--mr-85--ec3c8";
export var mr90 = "techStack-module--mr-90--80e5f";
export var mr95 = "techStack-module--mr-95--95be5";
export var mt0 = "techStack-module--mt-0--34c5e";
export var mt10 = "techStack-module--mt-10--f03e9";
export var mt100 = "techStack-module--mt-100--4b76f";
export var mt105 = "techStack-module--mt-105--328c7";
export var mt110 = "techStack-module--mt-110--99031";
export var mt115 = "techStack-module--mt-115--21761";
export var mt12 = "techStack-module--mt-12--dd020";
export var mt120 = "techStack-module--mt-120--69ea0";
export var mt130 = "techStack-module--mt-130--cf8ec";
export var mt140 = "techStack-module--mt-140--49684";
export var mt15 = "techStack-module--mt-15--bb386";
export var mt150 = "techStack-module--mt-150--3cbf8";
export var mt16 = "techStack-module--mt-16--7ab41";
export var mt18 = "techStack-module--mt-18--a3a83";
export var mt185 = "techStack-module--mt-185--e5d4f";
export var mt20 = "techStack-module--mt-20--72ce9";
export var mt25 = "techStack-module--mt-25--1fbe6";
export var mt30 = "techStack-module--mt-30--cea81";
export var mt35 = "techStack-module--mt-35--ce027";
export var mt40 = "techStack-module--mt-40--dbc72";
export var mt45 = "techStack-module--mt-45--8a31e";
export var mt48 = "techStack-module--mt-48--a2b5f";
export var mt5 = "techStack-module--mt-5--1f581";
export var mt50 = "techStack-module--mt-50--6bb94";
export var mt55 = "techStack-module--mt-55--d8145";
export var mt60 = "techStack-module--mt-60--9e1cb";
export var mt70 = "techStack-module--mt-70--a013b";
export var mt80 = "techStack-module--mt-80--79a84";
export var mt85 = "techStack-module--mt-85--f818f";
export var mt90 = "techStack-module--mt-90--6cd2a";
export var mt95 = "techStack-module--mt-95--d9437";
export var mv0 = "techStack-module--mv-0--00940";
export var mv10 = "techStack-module--mv-10--a5e59";
export var mv100 = "techStack-module--mv-100--02849";
export var mv105 = "techStack-module--mv-105--fc718";
export var mv110 = "techStack-module--mv-110--9290e";
export var mv115 = "techStack-module--mv-115--33521";
export var mv12 = "techStack-module--mv-12--f8a81";
export var mv120 = "techStack-module--mv-120--ebb5c";
export var mv130 = "techStack-module--mv-130--2c846";
export var mv140 = "techStack-module--mv-140--a5356";
export var mv15 = "techStack-module--mv-15--f014b";
export var mv150 = "techStack-module--mv-150--4c725";
export var mv16 = "techStack-module--mv-16--ed3b8";
export var mv18 = "techStack-module--mv-18--1e849";
export var mv185 = "techStack-module--mv-185--4c72c";
export var mv20 = "techStack-module--mv-20--404f6";
export var mv25 = "techStack-module--mv-25--d5e1d";
export var mv30 = "techStack-module--mv-30--99808";
export var mv35 = "techStack-module--mv-35--49ccb";
export var mv40 = "techStack-module--mv-40--92d04";
export var mv45 = "techStack-module--mv-45--9b189";
export var mv48 = "techStack-module--mv-48--16c98";
export var mv5 = "techStack-module--mv-5--9b629";
export var mv50 = "techStack-module--mv-50--02706";
export var mv55 = "techStack-module--mv-55--b41f8";
export var mv60 = "techStack-module--mv-60--a1ae4";
export var mv70 = "techStack-module--mv-70--76368";
export var mv80 = "techStack-module--mv-80--3a84a";
export var mv85 = "techStack-module--mv-85--0ec3f";
export var mv90 = "techStack-module--mv-90--bd764";
export var mv95 = "techStack-module--mv-95--8349f";
export var name = "techStack-module--name--132bb";
export var pa0 = "techStack-module--pa-0--bbbfb";
export var pa10 = "techStack-module--pa-10--954eb";
export var pa100 = "techStack-module--pa-100--88858";
export var pa105 = "techStack-module--pa-105--65766";
export var pa110 = "techStack-module--pa-110--adf1f";
export var pa115 = "techStack-module--pa-115--dd5fe";
export var pa12 = "techStack-module--pa-12--11eee";
export var pa120 = "techStack-module--pa-120--5cac8";
export var pa130 = "techStack-module--pa-130--05902";
export var pa140 = "techStack-module--pa-140--c59f7";
export var pa15 = "techStack-module--pa-15--88f1a";
export var pa150 = "techStack-module--pa-150--aa50d";
export var pa16 = "techStack-module--pa-16--374c0";
export var pa18 = "techStack-module--pa-18--37516";
export var pa185 = "techStack-module--pa-185--795bf";
export var pa20 = "techStack-module--pa-20--0fedc";
export var pa25 = "techStack-module--pa-25--1da58";
export var pa30 = "techStack-module--pa-30--e3ab7";
export var pa35 = "techStack-module--pa-35--1203c";
export var pa40 = "techStack-module--pa-40--22ed9";
export var pa45 = "techStack-module--pa-45--c82c4";
export var pa48 = "techStack-module--pa-48--cdecd";
export var pa5 = "techStack-module--pa-5--4490f";
export var pa50 = "techStack-module--pa-50--f0a91";
export var pa55 = "techStack-module--pa-55--cb9be";
export var pa60 = "techStack-module--pa-60--badd3";
export var pa70 = "techStack-module--pa-70--b472f";
export var pa80 = "techStack-module--pa-80--6bf5c";
export var pa85 = "techStack-module--pa-85--d0c4f";
export var pa90 = "techStack-module--pa-90--fb87c";
export var pa95 = "techStack-module--pa-95--c9d76";
export var pb0 = "techStack-module--pb-0--1d2a9";
export var pb10 = "techStack-module--pb-10--85717";
export var pb100 = "techStack-module--pb-100--7374d";
export var pb105 = "techStack-module--pb-105--e0ae6";
export var pb110 = "techStack-module--pb-110--9ac0b";
export var pb115 = "techStack-module--pb-115--ac0d6";
export var pb12 = "techStack-module--pb-12--d4eb0";
export var pb120 = "techStack-module--pb-120--cac26";
export var pb130 = "techStack-module--pb-130--48754";
export var pb140 = "techStack-module--pb-140--ae5a0";
export var pb15 = "techStack-module--pb-15--b3daa";
export var pb150 = "techStack-module--pb-150--15d01";
export var pb16 = "techStack-module--pb-16--8a537";
export var pb18 = "techStack-module--pb-18--98f44";
export var pb185 = "techStack-module--pb-185--4a5e7";
export var pb20 = "techStack-module--pb-20--f5c87";
export var pb25 = "techStack-module--pb-25--d9781";
export var pb30 = "techStack-module--pb-30--288f7";
export var pb35 = "techStack-module--pb-35--af3ad";
export var pb40 = "techStack-module--pb-40--ccd4b";
export var pb45 = "techStack-module--pb-45--10f9f";
export var pb48 = "techStack-module--pb-48--c2048";
export var pb5 = "techStack-module--pb-5--96629";
export var pb50 = "techStack-module--pb-50--ae578";
export var pb55 = "techStack-module--pb-55--14b42";
export var pb60 = "techStack-module--pb-60--04aed";
export var pb70 = "techStack-module--pb-70--2673b";
export var pb80 = "techStack-module--pb-80--ae7cc";
export var pb85 = "techStack-module--pb-85--bbd49";
export var pb90 = "techStack-module--pb-90--c07ed";
export var pb95 = "techStack-module--pb-95--e344c";
export var ph0 = "techStack-module--ph-0--c20a6";
export var ph10 = "techStack-module--ph-10--52fd2";
export var ph100 = "techStack-module--ph-100--97650";
export var ph105 = "techStack-module--ph-105--c449d";
export var ph110 = "techStack-module--ph-110--3c449";
export var ph115 = "techStack-module--ph-115--b6881";
export var ph12 = "techStack-module--ph-12--85854";
export var ph120 = "techStack-module--ph-120--fa6ca";
export var ph130 = "techStack-module--ph-130--462ea";
export var ph140 = "techStack-module--ph-140--7a44e";
export var ph15 = "techStack-module--ph-15--2d389";
export var ph150 = "techStack-module--ph-150--c2355";
export var ph16 = "techStack-module--ph-16--7156b";
export var ph18 = "techStack-module--ph-18--296d9";
export var ph185 = "techStack-module--ph-185--df6cf";
export var ph20 = "techStack-module--ph-20--5952a";
export var ph25 = "techStack-module--ph-25--e4ad3";
export var ph30 = "techStack-module--ph-30--84d65";
export var ph35 = "techStack-module--ph-35--89bf2";
export var ph40 = "techStack-module--ph-40--6aac7";
export var ph45 = "techStack-module--ph-45--81021";
export var ph48 = "techStack-module--ph-48--e8363";
export var ph5 = "techStack-module--ph-5--07b59";
export var ph50 = "techStack-module--ph-50--9a6d0";
export var ph55 = "techStack-module--ph-55--47b9b";
export var ph60 = "techStack-module--ph-60--07c69";
export var ph70 = "techStack-module--ph-70--1d863";
export var ph80 = "techStack-module--ph-80--fb63c";
export var ph85 = "techStack-module--ph-85--c1cd8";
export var ph90 = "techStack-module--ph-90--e8ba5";
export var ph95 = "techStack-module--ph-95--eec7a";
export var pl0 = "techStack-module--pl-0--85433";
export var pl10 = "techStack-module--pl-10--4859d";
export var pl100 = "techStack-module--pl-100--756f3";
export var pl105 = "techStack-module--pl-105--24551";
export var pl110 = "techStack-module--pl-110--7422e";
export var pl115 = "techStack-module--pl-115--24fd2";
export var pl12 = "techStack-module--pl-12--a3cfd";
export var pl120 = "techStack-module--pl-120--4cf90";
export var pl130 = "techStack-module--pl-130--12532";
export var pl140 = "techStack-module--pl-140--fc482";
export var pl15 = "techStack-module--pl-15--6e2c6";
export var pl150 = "techStack-module--pl-150--5fa62";
export var pl16 = "techStack-module--pl-16--8d14f";
export var pl18 = "techStack-module--pl-18--2dc79";
export var pl185 = "techStack-module--pl-185--2c54f";
export var pl20 = "techStack-module--pl-20--90c9f";
export var pl25 = "techStack-module--pl-25--999a1";
export var pl30 = "techStack-module--pl-30--0c31b";
export var pl35 = "techStack-module--pl-35--44cfe";
export var pl40 = "techStack-module--pl-40--b4d61";
export var pl45 = "techStack-module--pl-45--6d6a2";
export var pl48 = "techStack-module--pl-48--4c8eb";
export var pl5 = "techStack-module--pl-5--bf9ed";
export var pl50 = "techStack-module--pl-50--47393";
export var pl55 = "techStack-module--pl-55--65103";
export var pl60 = "techStack-module--pl-60--200f8";
export var pl70 = "techStack-module--pl-70--301a1";
export var pl80 = "techStack-module--pl-80--3ef5d";
export var pl85 = "techStack-module--pl-85--6e879";
export var pl90 = "techStack-module--pl-90--9bfd3";
export var pl95 = "techStack-module--pl-95--940e2";
export var pr0 = "techStack-module--pr-0--fb666";
export var pr10 = "techStack-module--pr-10--93554";
export var pr100 = "techStack-module--pr-100--ab6fe";
export var pr105 = "techStack-module--pr-105--32f6c";
export var pr110 = "techStack-module--pr-110--edd55";
export var pr115 = "techStack-module--pr-115--d64e3";
export var pr12 = "techStack-module--pr-12--63bf1";
export var pr120 = "techStack-module--pr-120--25f8a";
export var pr130 = "techStack-module--pr-130--c5c90";
export var pr140 = "techStack-module--pr-140--749ad";
export var pr15 = "techStack-module--pr-15--e640c";
export var pr150 = "techStack-module--pr-150--c5565";
export var pr16 = "techStack-module--pr-16--c5084";
export var pr18 = "techStack-module--pr-18--c1fb1";
export var pr185 = "techStack-module--pr-185--d5b6a";
export var pr20 = "techStack-module--pr-20--ba8a8";
export var pr25 = "techStack-module--pr-25--618e1";
export var pr30 = "techStack-module--pr-30--8b0f6";
export var pr35 = "techStack-module--pr-35--7f9d5";
export var pr40 = "techStack-module--pr-40--12334";
export var pr45 = "techStack-module--pr-45--a2413";
export var pr48 = "techStack-module--pr-48--0353b";
export var pr5 = "techStack-module--pr-5--1b690";
export var pr50 = "techStack-module--pr-50--2babf";
export var pr55 = "techStack-module--pr-55--f5ccf";
export var pr60 = "techStack-module--pr-60--becfc";
export var pr70 = "techStack-module--pr-70--bdfd8";
export var pr80 = "techStack-module--pr-80--fc0cf";
export var pr85 = "techStack-module--pr-85--adeed";
export var pr90 = "techStack-module--pr-90--010cb";
export var pr95 = "techStack-module--pr-95--9a3f0";
export var pt0 = "techStack-module--pt-0--212ba";
export var pt10 = "techStack-module--pt-10--ac2f2";
export var pt100 = "techStack-module--pt-100--7e94f";
export var pt105 = "techStack-module--pt-105--a5212";
export var pt110 = "techStack-module--pt-110--088a6";
export var pt115 = "techStack-module--pt-115--46c6d";
export var pt12 = "techStack-module--pt-12--86387";
export var pt120 = "techStack-module--pt-120--e8ea3";
export var pt130 = "techStack-module--pt-130--8eb85";
export var pt140 = "techStack-module--pt-140--5bccf";
export var pt15 = "techStack-module--pt-15--7ee78";
export var pt150 = "techStack-module--pt-150--736a5";
export var pt16 = "techStack-module--pt-16--fe2aa";
export var pt18 = "techStack-module--pt-18--26b71";
export var pt185 = "techStack-module--pt-185--2bb97";
export var pt20 = "techStack-module--pt-20--27dc3";
export var pt25 = "techStack-module--pt-25--c4a6f";
export var pt30 = "techStack-module--pt-30--5263f";
export var pt35 = "techStack-module--pt-35--1fbad";
export var pt40 = "techStack-module--pt-40--78a34";
export var pt45 = "techStack-module--pt-45--cb646";
export var pt48 = "techStack-module--pt-48--5a282";
export var pt5 = "techStack-module--pt-5--e1b7c";
export var pt50 = "techStack-module--pt-50--d6e33";
export var pt55 = "techStack-module--pt-55--f6723";
export var pt60 = "techStack-module--pt-60--d6577";
export var pt70 = "techStack-module--pt-70--14c62";
export var pt80 = "techStack-module--pt-80--2d24f";
export var pt85 = "techStack-module--pt-85--de615";
export var pt90 = "techStack-module--pt-90--27279";
export var pt95 = "techStack-module--pt-95--0b9b8";
export var pv0 = "techStack-module--pv-0--722b1";
export var pv10 = "techStack-module--pv-10--d6063";
export var pv100 = "techStack-module--pv-100--f5866";
export var pv105 = "techStack-module--pv-105--a993e";
export var pv110 = "techStack-module--pv-110--511fb";
export var pv115 = "techStack-module--pv-115--bcd9c";
export var pv12 = "techStack-module--pv-12--f3425";
export var pv120 = "techStack-module--pv-120--cb2d8";
export var pv130 = "techStack-module--pv-130--bbb9c";
export var pv140 = "techStack-module--pv-140--0b580";
export var pv15 = "techStack-module--pv-15--c98ee";
export var pv150 = "techStack-module--pv-150--f8460";
export var pv16 = "techStack-module--pv-16--8b669";
export var pv18 = "techStack-module--pv-18--d286a";
export var pv185 = "techStack-module--pv-185--b1495";
export var pv20 = "techStack-module--pv-20--18494";
export var pv25 = "techStack-module--pv-25--2df41";
export var pv30 = "techStack-module--pv-30--9c45d";
export var pv35 = "techStack-module--pv-35--55282";
export var pv40 = "techStack-module--pv-40--7fdba";
export var pv45 = "techStack-module--pv-45--d4a71";
export var pv48 = "techStack-module--pv-48--ec8de";
export var pv5 = "techStack-module--pv-5--ac685";
export var pv50 = "techStack-module--pv-50--b0850";
export var pv55 = "techStack-module--pv-55--97015";
export var pv60 = "techStack-module--pv-60--c35ae";
export var pv70 = "techStack-module--pv-70--bbbf4";
export var pv80 = "techStack-module--pv-80--962a3";
export var pv85 = "techStack-module--pv-85--ccd3f";
export var pv90 = "techStack-module--pv-90--8b202";
export var pv95 = "techStack-module--pv-95--58ad3";
export var stackContainer = "techStack-module--stackContainer--d648b";
export var stackListContainer = "techStack-module--stackListContainer--e419f";
export var title = "techStack-module--title--e9752";
export var wrapper = "techStack-module--wrapper--21557";