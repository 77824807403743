import React from "react";
import { graphql } from "gatsby";
import ProjectDetails from "components/projectDetails";
import TechStack from "components/techStack";
import LetsTalkComponent from "components/letsTalkComponent";
import Seo from "components/seo";
import { getSingleProjectDetailsProps } from "helpers/getSingleProjectDetailsProps";
import { getSingleProjectPageData } from "helpers/getSingleProjectPageData";
import Layout from "components/layout";

const Project = ({ data }) => {
  const { seo, letsTalk } = getSingleProjectPageData(data);
  const techStackList = data.strapiProject.tech_stacks;
  return (
    <Layout>
      <Seo {...seo} />
      <ProjectDetails {...getSingleProjectDetailsProps(data)} />
      <TechStack {...techStackList} />
      <LetsTalkComponent {...letsTalk} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiProject(slug: { eq: $slug }) {
      projectTitle
      projectSubtitle
      slug
      type
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      about {
        text
        title
      }
      location {
        text
        title
      }
      result {
        text
        title
      }
      website {
        text
        title
      }
      challenge {
        text {
          data {
            text
          }
        }
        title
      }
      imageAbout {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      resultImages {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      tech_stacks {
        name
        logo {
          alternativeText
          localFile {
            url
          }
        }
      }
    }
    strapiSingleProjectPage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          alternativeText
          localFile {
            url
          }
        }
      }
      tech_stacks {
        name
        logo {
          alternativeText
          localFile {
            url
          }
        }
      }
      letsTalk {
        title
        subtitle
        btn
        bgDark
        address
        btnArrow {
          alternativeText
          localFile {
            url
          }
        }
        preezmaLogo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default Project;
