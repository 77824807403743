import React from "react";
import * as styles from "./article.module.scss";

const Article = ({ text, title }) => {
  return (
    <>
      <p className={`${styles.title} mb-25`}>{title}</p>
      <p className={styles.text}> {text}</p>
    </>
  );
};

export default Article;
