import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Article from "components/article";
import { getImgProps } from "helpers/getImgProps";
import * as styles from "./projectDetails.module.scss";

const ProjectDetails = ({
  projectTitle,
  projectSubtitle,
  about,
  location,
  website,
  challenge,
  result,
  image,
  imageAbout,
  resultImages,
}) => {
  const imageList = resultImages.map((image, index) => {
    return (
      <div key={index} className={`${styles.banner} mb-50`}>
        <GatsbyImage {...getImgProps(image)} />
      </div>
    );
  });
  return (
    <>
      <div className={styles.wrapper}>
        <Link to="/case-studies">
          <p className={`${styles.title} mt-60`}>Projects</p>
        </Link>
        <p className={`${styles.projectTitle} mb-15`}>{projectTitle}</p>
        <p className={`${styles.projectSubtitle} mb-60`}>{projectSubtitle}</p>
        <div className="d-flex flex-lg-row flex-column justify-content-lg-between align-items-start mb-90">
          <div className={styles.imgContainer}>
            <GatsbyImage {...getImgProps(image)} />
          </div>
          <div className={styles.textContainer}>
            <div className="mb-70">
              <Article {...about} />
            </div>
            <div className="mb-40">
              <Article {...location} />
            </div>
            <Article {...website} />
          </div>
        </div>
      </div>
      <div className={`${styles.result} mt-60 mb-80`}>
        <Article title={challenge.title} text={challenge.text.data.text} />
      </div>
      {result.text === "Ongoing" ? (
        <div
          className={`${styles.images} d-flex flex-lg-row flex-column justify-content-lg-between align-items-start mb-30`}
        >
          <div className={`${styles.banner} mb-50`}>
            <GatsbyImage {...getImgProps(imageAbout)} className="mb-30" />
          </div>
          {imageList}
        </div>
      ) : (
        <>
          <div className={styles.img}>
            <GatsbyImage {...getImgProps(imageAbout)} />
          </div>
          <div className={`${styles.result} mt-60 mb-80`}>
            <Article {...result} />
          </div>
          <div
            className={`${styles.imageList} d-flex flex-lg-row flex-column justify-content-lg-between align-items-start mb-30`}
          >
            {imageList}
          </div>
        </>
      )}
    </>
  );
};

export default ProjectDetails;
